import * as React from "react"
import Layout from "../../components/Layout"
import { Row, Col } from "react-bootstrap"
import Section from "../../components/Section"
import { useStaticQuery, graphql, Link } from "gatsby"

const IndexPage = () => {
    const data = useStaticQuery(graphql`
        {
            allConferenceLogosJson {
                edges {
                    node {
                        name
                        filename
                        description
                        background
                    }
                }
            }
        }
    `)

    return (
        <Layout pageTitle="Conference & Awards - Logos">
            <Row>
                <Col>
                    <Link to="/conference">Back</Link>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={8}></Col>
            </Row>
            {data.allConferenceLogosJson.edges.map(({ node }, i) => (
                <LogoRow key={i} node={node} />
            ))}
        </Layout>
    )
}

const LogoRow = ({ node }) => {
    return (
        <Row className="mt-5">
            <Col md={6}>
                <Section bg={node.background} centered className="rounded p-2">
                    <img
                        src={`/img/download/conference/${node.filename}.svg`}
                        style={{ maxHeight: "250px" }}
                        alt=""
                    />
                </Section>
            </Col>
            <Col>
                <h2>{node.name}</h2>
                <p className="para">{node.description}</p>
                <a
                    href={`/img/download/conference/${node.filename}.png`}
                    download={`${node.name}.png`}
                    className="btn btn-primary m-3"
                >
                    Download PNG
                </a>
                <a
                    href={`/img/download/conference/${node.filename}.svg`}
                    download={`${node.name}.svg`}
                    className="btn btn-primary m-3"
                >
                    Download SVG
                </a>
            </Col>
        </Row>
    )
}

export default IndexPage
